import React from "react"
import DivSlider from "./DivSlider"
import { getTimeAgo } from "../utils/dates"

const sliderOptions = {
  slidesToShow: 1,
  dots: true,
  arrows: false,
  centerMode: false,
  hideGradientEdges: true,
  pauseOnHover: false,
  appendDots: (dots) => (
    <div
      style={{
        position: "absolute",
        bottom: 0,
      }}
    >
      <ul
        style={{ margin: "0px" }}
        className="[&>li]:mx-0 md:[&>li]:mx-0.5 [&_button]:before:!text-[0.5rem] md:[&_button]:before:!text-xs [&_button]:!px-0.5 [&_button]:before:!text-white [&_button]:before:text-shadow-sm [&_button]:before:shadow-black"
      >
        {dots}
      </ul>
    </div>
  ),
}

const GalleryEntry = ({ images, html, createdAt, last, language, translated }) => {
  return (
    <>
      <div className="mx-auto mt-12 w-full md:w-10/12 lg:w-9/12 xl:w-8/12 bg-stone-200 dark:bg-stone-800 rounded-xl shadow-lg shadow-neutral-500 dark:shadow-black flex flex-col gap-2 md:gap-3 lg:gap-4 overflow-hidden md:text-lg">
        <DivSlider options={{ ...sliderOptions, autoplaySpeed: Math.random() * 1000 + 9500 }}>
          {images.map(({ path, filename }, i) => (
            <img src={path} alt={filename} key={i + filename} className="aspect-video object-cover w-full" />
          ))}
        </DivSlider>
        <div className="flex flex-row-reverse justify-between px-[3%] md:px-[7%] lg:px-[10%] text-gray-600 dark:text-gray-400 font-light text-sm md:text-lg">
          <p>
            <span className="text-gray-800 dark:text-gray-200">Publ. </span>
            {getTimeAgo(createdAt, language)}
          </p>
          <p className="italic">{html ? translated : ""}</p>
        </div>
        <div
          className="px-6 lg:px-10 [&>p]:my-0 [&_a]:text-amber-700 dark:[&_a]:text-amber-400"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {/* Separation from bottom if html content is shown */ html && <div />}
      </div>
      {!last && (
        <hr className="mx-auto mt-14 w-8/12 md:w-7/12 lg:w-6/12 xl:w-5/12 border-t-2 border-amber-700 border-opacity-40" />
      )}
    </>
  )
}

export default GalleryEntry
