import React from "react"
import { useTranslation } from "react-i18next"

const Home = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="absolute w-screen h-screen overflow-hidden bg-neutral-600 dark:bg-neutral-950 -z-10">
        <div className="absolute w-[150vw] h-[200vh] top-1/2 left-1/2 bg-[url('./assets/img/casa_colonial.jpeg')] bg-center animate-bouncing-bg opacity-70 dark:opacity-40" />
      </div>
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="sticky top-10 md:top-0 py-10 w-fit font-trajanPro font-black text-center grayscale-[30%] dark:grayscale-[10%] dark:brightness-[60%]">
          <h1 content="Barroco" className="h1 my-0 text-5xl lg:text-7xl xl:text-9xl text-gradient-shadow !leading-[1]">
            Barroco
          </h1>
          <div className="h-12 md:h-14 lg:h-20 xl:h-[7rem] mx-auto -mt-2 md:-mt-4 lg:-mt-7 bg-[url('./assets/img/baroque-ornaments.png')] bg-cover bg-[0%_84%] filter grayscale sepia contrast-[90%] brightness-[90%]" />
          <h2 content={t("restBar")} className="h2 relative m-3 text-xl lg:text-3xl xl:text-5xl text-gradient-shadow">
            {t("restBar")}
          </h2>
          <p
            content={"– Santa Fé de Antioquia –"}
            className="text-lg lg:text-2xl xl:text-4xl italic before:from-yellow-100 before:via-amber-50 before:!to-yellow-100 text-gradient-shadow"
          >
            &#x2013;&nbsp;&nbsp;Santa Fé de Antioquia&nbsp;&nbsp;&#x2013;
          </p>
        </div>
      </div>
    </>
  )
}

export default Home
