import React from "react"
import { FaStar, FaRegStar, FaStarHalfStroke } from "react-icons/fa6"

const Star = ({ index, onClick, rating, style }) => {
  if (rating <= index) {
    return <FaRegStar onClick={onClick} style={style} />
  } else if (rating < index + 1) {
    return <FaStarHalfStroke onClick={onClick} style={style} />
  } else {
    return <FaStar onClick={onClick} style={style} />
  }
}

const Stars = ({ rating, setRating, className }) => (
  <div className={`flex items-center ${setRating ? "gap-x-1" : "gap-x-0"} ${className}`}>
    {Array(5)
      .fill()
      .map((_, i) => (
        <Star
          key={i}
          index={i}
          rating={rating}
          onClick={() => {
            setRating && setRating(i > 0 ? i + 1 : rating === 1 ? 0 : 1)
          }}
          style={setRating && { cursor: "pointer" }}
        />
      ))}
  </div>
)

export default Stars
