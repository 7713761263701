import React from "react"
import { useTranslation } from "react-i18next"
import Form from "../components/Form"

const Contact = () => {
  const { t } = useTranslation()
  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={t("navbar.contact")}>
        {t("navbar.contact")}
      </h1>

      <Form
        data={{
          title: t("contact.title"),
          inputs: [
            { type: "text", name: "name", label: t("contact.name"), required: true },
            { type: "email", name: "email", label: t("contact.email"), required: true },
            { type: "tel", name: "phone", label: t("contact.phone"), required: true },
            { type: "country", name: "country", label: t("contact.country"), data: "title" },
            { type: "textarea", name: "text", label: t("contact.text"), required: true },
          ],
          submit: {
            submitText: t("send"),
            submitEndpoint: "/api/contact",
            resultOk: "contact.result.ok",
            resultError: "contact.result.error",
          },
        }}
      />
    </div>
  )
}

export default Contact
