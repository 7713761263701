import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import { FaCloudUploadAlt } from "react-icons/fa"
import { fromBytes, fileToBase64 } from "../utils/utils.js"
import Loading from "./Loading.jsx"

export const FileInput = ({ name, t, required, options, extensions, disabled }) => {
  const [fileReject, setFileReject] = useState(null)
  const [isLoadingFile, setIsLoadingFile] = useState(false)
  const [inputValue, setInputValue] = useState(required ? "" : "json-[]")
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({
    ...options,
    disabled,
    onDropAccepted: async (files) => {
      /* // https://github.com/react-dropzone/react-dropzone/issues/880#issuecomment-1728920508
      if (hiddenInputRef.current) {
        // Note the specific way we need to munge the file into the hidden input
        // https://stackoverflow.com/a/68182158/1068446
        const dataTransfer = new DataTransfer()
        files.forEach((v) => dataTransfer.items.add(v))
        hiddenInputRef.current.files = dataTransfer.files
      } */
      setIsLoadingFile(true)
      try {
        const data = await Promise.all(files.map(async (f) => await fileToBase64(f)))
        setInputValue("json-" + JSON.stringify(data))
      } catch (err) {
        alert(`ERROR\n${err}`)
      } finally {
        setIsLoadingFile(false)
        setFileReject(null)
      }
    },
    onDropRejected: (d) => {
      setFileReject(
        d.map((data) => {
          const { file, errors } = data
          return `- ${file.name} => ${errors[0].message}`
        })
      )
      setInputValue(required ? "" : "json-[]")
    },
  })
  const { maxSize } = options
  const requirements = extensions.reduce(
    (prev, curr, i) =>
      i === 0 ? `${prev} ${curr}` : i < extensions.length - 1 ? `${prev}, ${curr}` : `${prev} ${t("and")} ${curr}`,
    t("files")
  )

  return (
    <div {...getRootProps()}>
      {isLoadingFile && <Loading />}

      <div
        className={
          "border-[1px] border-neutral-600 border-dashed bg-neutral-200 bg-opacity-20 rounded lg:rounded-xl flex flex-col justify-center items-center py-2 text-sm lg:text-lg px-4" +
          (isLoadingFile ? " opacity-0" : "")
        }
      >
        <input
          type="text"
          name={name}
          value={inputValue}
          onChange={() => {}}
          required={required}
          className="absolute opacity-0"
        />
        <input {...getInputProps()} />
        <FaCloudUploadAlt className="w-8 h-8 lg:w-12 lg:h-12 opacity-60" />
        {acceptedFiles.length ? (
          acceptedFiles.map((file) => (
            <p
              key={file.path}
              className="text-center leading-5 border-t-[1px] border-neutral-500 border-opacity-50 w-full px-4 py-2"
            >
              {file.path} - {fromBytes(file.size)}
            </p>
          ))
        ) : fileReject ? (
          <>
            <p className="text-red-700 font-bold">ERROR</p>
            {fileReject.map((e) => (
              <p className="text-red-700" key={e}>
                {e}
              </p>
            ))}
          </>
        ) : isDragActive ? (
          <p>{t("dropHere")} ...</p>
        ) : (
          <>
            <p>{t("dragDropOrClick")}</p>
            <p>{`${requirements} / ${t("max")} ${fromBytes(maxSize)}`}</p>
          </>
        )}
      </div>
    </div>
  )
}
