import React from "react"
import { useTranslation } from "react-i18next"
import Form from "../components/Form"

const WorkWithUs = () => {
  const { t } = useTranslation()
  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={t("navbar.workWithUs")}>
        {t("navbar.workWithUs")}
      </h1>

      <Form
        data={{
          title: t("workWithUs.title"),
          inputs: [
            { type: "text", name: "name", label: t("workWithUs.name"), required: true },
            { type: "email", name: "email", label: t("workWithUs.email"), required: true },
            { type: "tel", name: "phone", label: t("workWithUs.phone"), required: true },
            { type: "textarea", name: "cover-letter", label: t("workWithUs.coverLetter"), required: true },
            {
              type: "file",
              name: "cv",
              label: t("workWithUs.cv"),
              required: true,
              options: {
                maxSize: Math.pow(1024, 2) * 5,
                multiple: false,
                accept: {
                  "application/msword": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
                  "application/pdf": [],
                },
              },
              extensions: [".pdf", ".docx", ".doc"],
            },
            {
              type: "file",
              name: "other",
              label: t("workWithUs.other"),
              options: {
                maxSize: Math.pow(1024, 2) * 5,
                multiple: true,
                accept: {
                  "application/msword": [],
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
                  "application/pdf": [],
                },
              },
              extensions: [".pdf", ".docx", ".doc"],
            },
          ],
          submit: {
            submitText: t("send"),
            submitEndpoint: "/api/work-with-us",
            resultOk: "workWithUs.result.ok",
            resultError: "workWithUs.result.error",
          },
        }}
      />
    </div>
  )
}

export default WorkWithUs
