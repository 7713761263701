/* global google */

import React, { useCallback, useContext, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { GoogleMap, MarkerF, DirectionsRenderer, useJsApiLoader } from "@react-google-maps/api"
import { BARROCO_LAT_LNG, BARROCO_MAP_ID, GOOGLE_MAPS_API_KEY } from "../utils/constants"
import { ThemeContext } from "../hooks/useTheme"
import Places from "../components/Places"
import Loading from "../components/Loading"

/**
 * Based on the YouTube tutorial
 * https://www.youtube.com/watch?v=2po9_CIRW7I&t=2469s
 **/

const loadScriptData = { googleMapsApiKey: GOOGLE_MAPS_API_KEY, libraries: ["marker", "places"] }

const Location = () => {
  /**
   * useLoadScript ==> sync load
   * loadScriptData ==> async load
  const { isLoaded } = useLoadScript(loadScriptData)
  */
  const { isLoaded } = useJsApiLoader(loadScriptData)
  const [directions, setDirections] = useState({ routes: [] })
  const [isSearching, setIsSearching] = useState(false)
  const { theme } = useContext(ThemeContext)
  const mapRef = useRef()
  const options = useMemo(
    () => ({ mapId: BARROCO_MAP_ID[theme], /* disableDefaultUI: true, */ clickableIcons: false }),
    [theme]
  )
  const onLoad = useCallback((map) => (mapRef.current = map), [])
  const { t } = useTranslation()

  const calculateDirections = (origin) => {
    if (!origin) {
      setDirections({ routes: [] })
      mapRef.current?.panTo(BARROCO_LAT_LNG)
      mapRef.current?.setZoom(17)
      return
    }
    // Obtain the route from Google's API
    new google.maps.DirectionsService().route(
      {
        origin,
        destination: BARROCO_LAT_LNG,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK" && result) {
          setDirections(result)
        } else {
          window.alert("Directions request failed due to " + status)
        }
      }
    )
  }

  const handleCurrentLocationClick = async () => {
    // Get current location latlng
    // https://developers.google.com/maps/documentation/javascript/examples/map-geolocation
    // https://stackoverflow.com/questions/51843227/how-to-use-async-wait-with-html5-geolocation-api
    // Try HTML5 geolocation.
    setIsSearching(true)
    try {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error)
        )
      })
      const currentLocation = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      }
      calculateDirections(currentLocation)
    } catch (err) {
      alert(`${t("location.errorGeoLocation")}\n\n${err.message}`)
    }
    setIsSearching(false)
  }

  return (
    <div className="base md:h-screen">
      <h1 className="h1 text-gradient-shadow" content={t("location.title")}>
        {t("location.title")}
      </h1>
      <div className="flex-1 flex flex-col md:flex-row mt-8 gap-8 relative">
        {isLoaded ? (
          <>
            <GoogleMap
              zoom={17}
              center={BARROCO_LAT_LNG}
              mapContainerClassName="flex-1 min-h-96"
              options={options}
              onLoad={onLoad}
              /* Key forces map to re-render on theme change */
              key={theme}
            >
              <DirectionsRenderer
                directions={directions}
                options={{
                  polylineOptions: {
                    strokeColor: theme === "light" ? "#f59e0b" /* amber-500 */ : "#fbbf24" /* amber-400 */,
                  },
                }}
              />
              <MarkerF
                position={BARROCO_LAT_LNG}
                title="Barroco"
                icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
              />
              {isSearching && (
                <div className="absolute left-0 top-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-30">
                  <Loading color="border-amber-700 dark:border-amber-400" size="6rem" />
                </div>
              )}
            </GoogleMap>
            <div className="w-full md:w-4/12 flex flex-col items-center text-lg lg:text-xl">
              <p className="font-semibold text-2xl">{t("location.getDirections")}</p>
              <p className="self-start my-3">{t("location.currentLocation")}:</p>
              <button
                className="px-6 py-3 rounded-lg bg-neutral-300 hover:bg-amber-300 active:bg-amber-200 border-2 border-neutral-500 dark:text-yellow-950"
                onClick={handleCurrentLocationClick}
              >
                {t("location.letsGo")}
              </button>
              <p className="self-start my-3">{t("location.otherLocation")}:</p>
              <Places setIsSearching={setIsSearching} setOrigin={calculateDirections} />
            </div>
          </>
        ) : (
          <Loading color="border-amber-700 dark:border-amber-400" size="6rem" margin="mx-auto my-20" />
        )}
      </div>
    </div>
  )
}

export default Location
