import puenteColgante from "../assets/img/puente-de-occidente.jpeg"
import museoJuanDelCorral from "../assets/img/museo-juan-del-corral.jpeg"
import museoArteReligioso from "../assets/img/museo-arte-religioso.jpeg"
import iglesiaChiquinquira from "../assets/img/iglesia-chinca.jpeg"
import plazaChinca from "../assets/img/plaza-chinca.webp"
import parquePrincipal from "../assets/img/parque-principal.webp"
import catedral from "../assets/img/catedral-basilica.jpeg"
import iglesiaSantaBarbara from "../assets/img/iglesia-santa-barbara.jpeg"
import parqueSantaBarbara from "../assets/img/parque-santa-barbara.jpeg"
import iglesiaJesusNazareno from "../assets/img/iglesia-jesus-nazareno.jpeg"
import parqueJesusNazareno from "../assets/img/parque-jesus-nazareno.webp"
import iglesiaSenoraCarmen from "../assets/img/iglesia-senora-carmen.jpeg"
import iglesiaSanMartinPorres from "../assets/img/iglesia-san-martin-porres.jpeg"
import iglesiaSanPedroClaver from "../assets/img/iglesia-san-pedro-claver.jpeg"

export const toDoPlaces = [
  {
    id: "puenteOccidente",
    image: puenteColgante,
    imageSource: "vivirenelpoblado.com",
    location:
      "https://www.google.com/maps/place/Puente+Colgante+de+Occidente/@6.5775307,-75.8006077,17z/data=!4m6!3m5!1s0x8e45b5d048c86ad5:0x1b2ee3d17684b6d!8m2!3d6.5778824!4d-75.7984083!16s%2Fg%2F1216fpk3?entry=ttu",
    metersDistance: 5510,
    price: "free",
    readMore: [
      { website: "Colparques", url: "https://www.colparques.net/PUENTE" },
      { website: "Wikipedia", url: "https://es.wikipedia.org/wiki/Puente_de_Occidente" },
    ],
    en: {
      title: "Santa Fe Suspension Bridge",
      desc: (
        <p>
          Built between 1887 and 1895 by engineer Jose Maria Villa. It was considered the seventh most important
          suspension bridge of its time, it was the longest in the subcontinent, and there lies the historical and
          cultural importance of the bridge, being a key piece in the development of the region and the country. It
          communicates Olaya and Santa Fe de Antioquia towns. It is crossed on foot but you can get there by car and
          leave it in the parking lots.
        </p>
      ),
      schedule: <p>Open 24/7</p>,
    },
    es: {
      title: "Puente Colgante de Occidente",
      desc: (
        <p>
          Construido entre 1887 y 1895 por el Ingeniero José María Villa. Fue considerado en su momento como el séptimo
          puente colgante más importante en todo el mundo, era el más largo de todo el subcontinente en su tipo, y es
          allí donde radica la importancia histórica y cultural del puente, siendo en su momento pieza clave en el
          desarrollo de la región y el país. Comunica los municipios de Olaya y Santa Fé de Antioquia. Tiene paso
          peatonal pero puedes llegar en vehículo y dejarlo parqueado en los lugares indicados para esto.
        </p>
      ),
      schedule: <p>Abierto todos los días</p>,
    },
  },

  {
    id: "juanDelCorral",
    image: museoJuanDelCorral,
    imageSource: "elturismoencolombia.com",
    location:
      "https://www.google.com/maps/place/Museo+Juan+Del+Corral/@6.557716,-75.8284401,18z/data=!4m6!3m5!1s0x8e45b61712e7a4e9:0xc3e8ffd8329cb39a!8m2!3d6.5577376!4d-75.8273249!16s%2Fg%2F1hdz952fh?entry=ttu",
    metersDistance: 50,
    price: "free",
    readMore: [{ website: "SIMCO", url: "http://simco.museoscolombianos.gov.co/Home/Museo?personaJuridicaId=458" }],
    en: {
      title: "Juan del Corral Museum",
      desc: (
        <p>
          Located in a typical colonial house, the museum exhibits several antique objects of great historical value.
          You can be accompanied by tour guides and there you will find a collection of the history of Santa Fe de
          Antioquia from the centuries XVI, XVII, XVIII and XIX.
        </p>
      ),
      schedule: (
        <ul className="list-none">
          <li>Saturday and sunday 10 a.m.-5 p.m.</li>
          <li>Monday to friday 9 a.m.-12 p.m., 2-5 p.m.</li>
          <li>Wednesday closed</li>
        </ul>
      ),
    },
    es: {
      title: "Museo Juan del Corral",
      desc: (
        <p>
          Está ubicado en una casa colonial típica, y el museo muestra varios objetos pasados de mucho valor histórico.
          Puedes ir acompañado de guías y encontrarás una colección de la historia de Santa Fé de Antioquia, siglos XVI,
          XVII, XVIII y XIX.
        </p>
      ),
      schedule: (
        <ul className="list-none">
          <li>Sábados y domingos 10 a.m.-5 p.m.</li>
          <li>Lunes a viernes 9 a.m.-12 p.m., 2-5 p.m.</li>
          <li>Miércoles cerrado</li>
        </ul>
      ),
    },
  },

  {
    id: "franciscoCristobalToro",
    image: museoArteReligioso,
    imageSource: "flickr.com",
    location:
      "https://www.google.com/maps/place/Museo+de+Arte+Religioso+Francisco+Crist%C3%B3bal+Toro/@6.5571117,-75.8267369,19z/data=!3m1!4b1!4m6!3m5!1s0x8e45b6176e9f6ee1:0x4b917cb6d3801510!8m2!3d6.5571117!4d-75.8260918!16s%2Fg%2F11b7q7fl4w?entry=ttu",
    metersDistance: 210,
    price: 3000,
    readMore: [
      {
        website: "ILAM Patrimonio",
        url: "https://ilamdir.org/recurso/1859/museo-de-arte-religioso-francisco-crist%C3%B3bal-toro",
      },
    ],
    en: {
      title: "Francisco Cristobal Toro Religious Art Museum",
      desc: (
        <p>
          Located next to Santa Barbara church, the museum occupies part of the building made by Jesuit fathers in the
          third decade of XVIII century. Its collection includes decorative elements of colonial period, including gold
          pieces, paintings, and two rooms dedicated to Holy Week and Mother Laura, the only colombian saint, that
          protected natives and the helpless.
        </p>
      ),
      schedule: (
        <ul className="list-none">
          <li>Saturday and sunday 11 a.m.-5:30 p.m.</li>
          <li>Monday to friday 2-5:30 p.m.</li>
          <li>Tuesday closed</li>
        </ul>
      ),
    },
    es: {
      title: "Museo de Arte Religioso Francisco Cristóbal Toro",
      desc: (
        <p>
          Situado al lado de la iglesia de Santa Bárbara, el museo ocupa parte del edificio construido por los padres
          Jesuitas en la tercera década del siglo XVIII. Su colección incluye elementos decorativos de la época de la
          colonia, como piezas hechas de oro, pinturas y dos salas dedicadas a la Semana Santa y a la Madre Laura, única
          santa colombiana, que protegió a los indígenas y a los más desvalidos.
        </p>
      ),
      schedule: (
        <ul className="list-none">
          <li>Sábados y domingos 11 a.m.-5:30 p.m.</li>
          <li>Lunes a viernes 2-5:30 p.m.</li>
          <li>Martes cerrado</li>
        </ul>
      ),
    },
  },

  {
    id: "iglesiaChiquinquira",
    image: iglesiaChiquinquira,
    imageSource: "serturista.com",
    location:
      "https://www.google.com/maps/place/Iglesia+de+Nuestra+Se%C3%B1ora+de+Chiquinquir%C3%A1/@6.5584454,-75.831271,17.96z/data=!4m6!3m5!1s0x8e45b63d7dd026e3:0xbe6390a8ddd09c4f!8m2!3d6.5584247!4d-75.8299441!16s%2Fg%2F1jmcwmq2c?entry=ttu",
    metersDistance: 280,
    price: "free",
    readMore: [
      {
        website: "Wikipedia",
        url: "https://es.wikipedia.org/wiki/Iglesia_de_Nuestra_Se%C3%B1ora_de_Chiquinquir%C3%A1_(Santa_Fe_de_Antioquia)",
      },
    ],
    en: {
      title: "Our Lady of Chiquinquira Church",
      desc: (
        <>
          <h3 className="text-center">(La Chinca Church)</h3>
          <p>
            Neoclasical style with barroco details. It served as a graveyard and it used to have a room destined to be a
            prison for clerics. La Chinca Church, as it is usually called, is named after the Virgin Mary under the
            Chiquinquira dedication. It is located at a side of the Martinez Pardo square, three blocks away of the
            Santa Fe de Antioquia's main square and is part of the eclesiastic jurisdiction of Santa Fe de Antioquia
            Archdiocese.
          </p>
        </>
      ),
    },
    es: {
      title: "Iglesia de Nuestra Señora de Chiquinquirá",
      desc: (
        <>
          <h3 className="text-center">(Iglesia de la Chinca)</h3>
          <p>
            Estilo neoclásico con detalle de barroco. Sirvió como cementerio y poseía un cuarto destinado a ser cárcel
            de clérigos. La Iglesia de la Chinca, como popularmente es llamada, es dedicada a la Virgen María bajo la
            advocación de la Virgen de Chiquinquirá. Está ubicado a un costado de la Plazuela Martínez Pardo a tres
            cuadras del parque principal de Santa Fé de Antioquia (Antioquia) y pertenece a la jurisdicción eclesiástica
            de la Arquidiócesis de Santa Fé de Antioquia.
          </p>
        </>
      ),
    },
  },

  {
    id: "parqueChinca",
    image: plazaChinca,
    imageSource: "elturismoencolombia.com",
    location:
      "https://www.google.com/maps/place/Parque+La+Chinca,+Santa+Fe+De+Antioquia/@6.5581764,-75.832183,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b617c4555555:0xd8424e70ecc61b8c!8m2!3d6.5581764!4d-75.8296027!16s%2Fg%2F11g7z6fpj0?entry=ttu",
    metersDistance: 280,
    price: "free",
    readMore: [
      {
        website: "Google",
        url: "https://www.google.co.ve/travel/entity/key/ChoIjLeY5o7Ok6HYARoNL2cvMTFnN3o2ZnBqMBAE?ved=0CAAQ5JsGahcKEwjgqeLfsLSGAxUAAAAAHQAAAAAQAw&ts=CAEaBAoCGgAqBAoAGgA",
      },
    ],
    en: {
      title: "La Chinca Park",
      desc: (
        <>
          <h3 className="text-center">(Martinez Pardo Square)</h3>
          <p>
            This park is located in front of La Chinca Church, a place with plenty of plants and colonial style. There
            you can find town's traditional candies shops and <em>souvenirs</em> for family and friends. In the middle
            of the park there is a monument with two faces, one native and one spanish, and above them the tribute to
            Marshal Robledo. Besides the church is the Archbishop's Palace.
          </p>
        </>
      ),
      schedule: <p>Open 24/7</p>,
    },
    es: {
      title: "Parque de la Chinca",
      desc: (
        <>
          <h3 className="text-center">(Plazuela Martínez Pardo)</h3>
          <p>
            Es un parque que se encuentra al frente de la Iglesia de la Chinca, un lugar lleno de vegetación y estilo
            colonial. Además, hay lugares para comprar dulces tradicionales del municipio y <em>souvenirs</em> para la
            familia y amigos. En el centro de este parque se encuentra el monumento que tiene en sus caras dos mujeres,
            una indígena y una española y, sobre ellas, el tributo al Mariscal Robledo. Junto a esta Iglesia se
            encuentra el Palacio Arzobispal.
          </p>
        </>
      ),
      schedule: <p>Abierto todos los días</p>,
    },
  },

  {
    id: "parquePrincipal",
    image: parquePrincipal,
    imageSource: "noticiascaracol.com",
    location:
      "https://www.google.com/maps/place/Parque+Principal+De+Santa+Fe,+Santa+F%C3%A9+de+Antioquia,+Antioquia/@6.5564293,-75.8299924,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b61786959817:0x52521a8b112f2958!8m2!3d6.556338!4d-75.827793!16s%2Fg%2F11bw3xzbcv?entry=ttu",
    metersDistance: 100,
    price: "free",
    readMore: [
      {
        website: "Colombia Travel",
        url: "https://colombia.travel/es/santa-fe-de-antioquia/parque-principal",
      },
    ],
    en: {
      title: "Simon Bolivar Main Square",
      desc: (
        <>
          <h3 className="text-center">(Historic Downtown)</h3>
          <p>
            There you will find a pedestrian promenade with plenty of trees and gardens, benches, lanterns, and a
            beautiful fountain that tells the history of the water treatment since the colony. A water fountain at the
            center, palms, ceibas and other trees decorate the Santa Fe de Antioquia Main Square, surrounded by a
            cobblestone. There are also craft stores all around where you can buy local candies.
          </p>
        </>
      ),
      schedule: <p>Open 24/7</p>,
    },
    es: {
      title: "Parque Principal Simón Bolívar",
      desc: (
        <>
          <h3 className="text-center">(Centro Histórico)</h3>
          <p>
            Allí encontrarás un paseo peatonal lleno de árboles y jardines, sillas, faroles y una hermosa fuente que nos
            narra la historia del manejo de las aguas en la época de la colonia. Una fuente de agua en el centro,
            palmas, ceibas y otros árboles adornan el parque principal de Santa Fe de Antioquia, rodeado por un
            empedrado. Alrededor también hay tiendas artesanales en las que se consiguen dulces de la región.
          </p>
        </>
      ),
      schedule: <p>Abierto todos los días</p>,
    },
  },

  {
    id: "catedral",
    image: catedral,
    imageSource: "wikipedia.org",
    location:
      "https://www.google.com/maps/place/Catedral+Bas%C3%ADlica+Metropolitana+de+la+Inmaculada+Concepci%C3%B3n/@6.5569862,-75.8300112,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b6177f44f601:0x415b7bc31ebba9f2!8m2!3d6.5569862!4d-75.8274309!16s%2Fg%2F121kww_y?entry=ttu",
    metersDistance: 70,
    price: "free",
    readMore: [
      {
        website: "Wikipedia",
        url: "https://es.wikipedia.org/wiki/Catedral_bas%C3%ADlica_de_la_Inmaculada_Concepci%C3%B3n_(Santa_Fe_de_Antioquia)",
      },
      {
        website: "Horarios Misa",
        url: "https://horariosmisa.co/antioquia/santa-fe-de-antioquia/catedral-basilica-metropolitana-de-la-inmaculada-concepcion/",
      },
    ],
    en: {
      title: "Metropolitan Cathedral Basilica of the Immaculate Conception",
      desc: (
        <p>
          Neoclassical Renaissance style, built between 1797 and 1837. Prison for priests in times past, it has numerous
          jewels and an art gallery in the sacristy. It is a cathedral church of Catholic worship dedicated to the
          Virgin Mary under the invocation of the Immaculate Conception. The building is located on the northeast side
          of the main square of the municipality of Santa Fe de Antioquia (Antioquia).
        </p>
      ),
    },
    es: {
      title: "Catedral Basílica Metropolitana de la Inmaculada Concepción",
      desc: (
        <p>
          Estilo neoclásico renacentista, construida entre 1797 y 1837. Cárcel de sacerdotes en épocas pasadas, tiene
          numerosas joyas y galería de arte en la sacristía. Es una iglesia catedralicia de culto católico dedicada a la
          Virgen María bajo la advocación de la Inmaculada Concepción. El edificio se encuentra ubicado en el costado
          nororiental de la plaza principal del municipio de Santa Fé de Antioquia (Antioquia).
        </p>
      ),
    },
  },

  {
    id: "iglesiaSantaBarbara",
    image: iglesiaSantaBarbara,
    imageSource: "arquisantioquia.co",
    location:
      "https://www.google.com/maps/place/Iglesia+Santa+B%C3%A1rbara/@6.5570506,-75.8283304,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b617421b8e31:0x40527881b1387fb9!8m2!3d6.5570506!4d-75.8257501!16s%2Fg%2F1yl48560r?entry=ttu",
    metersDistance: 240,
    price: "free",
    readMore: [
      {
        website: "Wikipedia",
        url: "https://es.wikipedia.org/wiki/Iglesia_de_Santa_B%C3%A1rbara_(Santa_Fe_de_Antioquia)",
      },
    ],
    en: {
      title: "Saint Barbara Church",
      desc: (
        <p>
          It is a Colombian temple of Catholic worship, dedicated to Saint Barbara. It is located on one side of Saint
          Barbara square, two blocks away from Main Square. The building is a popular Antioquian Baroque style in brick,
          stone and royal mixture built. Dating from 1726, being the oldest standing temple in the town it is called
          “the grandmother of the churches”.
        </p>
      ),
    },
    es: {
      title: "Iglesia Santa Bárbara",
      desc: (
        <p>
          Es un templo colombiano de culto católico, bajo la advocación de Santa Bárbara. Está ubicado a un costado de
          la Plazuela Santa Bárbara a dos cuadras del parque principal del municipio. El edificio es estilo Barroco
          popular antioqueño en ladrillo, piedra y mezcla real construida. Data de 1726, siendo el templo en pie más
          antiguo de la localidad llamada “la abuela de las iglesias”.
        </p>
      ),
    },
  },

  {
    id: "parqueSantaBarbara",
    image: parqueSantaBarbara,
    imageSource: "wikimedia.org",
    location:
      "https://www.google.com/maps/place/Parque+Santa+Barbara,+Cra.+8+%2310-98,+Santa+F%C3%A9+de+Antioquia,+Antioquia/@6.5567869,-75.8285306,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b61767d6ff47:0xe9b2e0a8b67fd19b!8m2!3d6.5568123!4d-75.8259317!16s%2Fg%2F11bvtf5w9z?entry=ttu",
    metersDistance: 240,
    price: "free",
    readMore: [
      {
        website: "Tripadvisor",
        url: "https://www.tripadvisor.es/LocationPhotoDirectLink-g672067-i89619336-Santa_Fe_de_Antioquia_Antioquia_Department.html",
      },
    ],
    en: {
      title: "Saint Barbara Square",
      desc: (
        <p>
          This park next to Saint Barbara Church has warm lighting ideal for taking photographs and resting peacefully.
        </p>
      ),
    },
    es: {
      title: "Parque Santa Bárbara",
      desc: (
        <p>
          Es un parque al frente de la Iglesia Santa Bárbara, con una iluminación cálida y óptima para tomar fotos y
          tener un descanso tranquilo.
        </p>
      ),
    },
  },

  {
    id: "iglesiaJesusNazareno",
    image: iglesiaJesusNazareno,
    imageSource: "pinterest.com",
    location:
      "https://www.google.com/maps/place/Iglesia+Jes%C3%BAs+Nazareno/@6.5545855,-75.8272507,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b61a28c49575:0xfdc076602a9f223a!8m2!3d6.5545855!4d-75.8246704!16s%2Fg%2F1tkkvy84?entry=ttu",
    metersDistance: 550,
    price: "free",
    readMore: [
      {
        website: "Wikipedia",
        url: "https://es.wikipedia.org/wiki/Iglesia_de_Jes%C3%BAs_Nazareno_(Santa_Fe_de_Antioquia)",
      },
      {
        website: "Horarios Misa",
        url: "https://horariosmisa.co/antioquia/santa-fe-de-antioquia/iglesia-jesus-nazareno/",
      },
    ],
    en: {
      title: "Nazarene Jesus Church",
      desc: (
        <p>
          Located at a side of Nazarene Jesus Square, three blocks away from Main Square. The building, better known as
          the Church of My Father Jesus, dates back to 1828, in a neoclassical style with baroque details, with a
          rectangular floor plan and a single nave. During the colony it enjoyed the right of asylum and served as a
          cemetery for priests. The historic sector (including the Nazarene Jesus Church) of Santa Fe de Antioquia,
          former capital of the department of Antioquia, was declared a National Monument of Colombia by Law 163 of
          December 30, 1959.
        </p>
      ),
    },
    es: {
      title: "Iglesia de Jesús Nazareno",
      desc: (
        <p>
          Está ubicado a un costado de la Plazuela Jesús Nazareno a tres cuadras del parque principal del municipio. El
          edificio, más conocido como la Iglesia de Mi Padre Jesús, data de 1828, de estilo neoclásico con detalles
          barrocos, de planta rectangular y de una sola nave. Durante la colonia gozó del derecho de asilo y sirvió como
          cementerio para sacerdotes. El sector histórico (incluyendo la Iglesia de Jesús Nazareno) de Santa Fé de
          Antioquia, antigua capital del departamento de Antioquia, fue declarado Monumento Nacional de Colombia por la
          Ley 163 del 30 de diciembre de 1959.
        </p>
      ),
    },
  },

  {
    id: "parqueJesusNazareno",
    image: parqueJesusNazareno,
    imageSource: "viajesyfotografia.com",
    location:
      "https://www.google.com/maps/place/Plazuela+de+Jes%C3%BAs+Nazareno/@6.5547206,-75.8274812,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b7e5f6214f07:0xc51cf9c6e7af0047!8m2!3d6.5547206!4d-75.8249009!16s%2Fg%2F11k43jmh29?entry=ttu",
    metersDistance: 550,
    price: "free",
    readMore: [
      {
        website: "Mapcarta",
        url: "https://mapcarta.com/es/W45668038",
      },
    ],
    en: {
      title: "Nazarene Jesus Square",
      desc: (
        <p>
          On this site is the church of Jesús Nazareno, it also has a fountain, benches and the bust of the poet Jorge
          Robledo Ortiz.
        </p>
      ),
      schedule: <p>Open 24/7</p>,
    },
    es: {
      title: "Parque Jesús Nazareno",
      desc: (
        <p>
          En este sitio se encuentra la iglesia de Jesús Nazareno, además cuenta con una fuente, bancas y el busto del
          poeta Jorge Robledo Ortiz.
        </p>
      ),
      schedule: <p>Abierto todos los días</p>,
    },
  },

  {
    id: "iglesiaSenoraCarmen",
    image: iglesiaSenoraCarmen,
    imageSource: "turismoantioquia.travel",
    location:
      "https://www.google.com/maps/place/Iglesia+Nuestra+Se%C3%B1ora+del+Carmen/@6.5527197,-75.8249223,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b61bb9cab7d7:0x4d04df57213dcb85!8m2!3d6.5527197!4d-75.822342!16s%2Fg%2F11b7qbh5_8?entry=ttu",
    metersDistance: 900,
    price: "free",
    readMore: [
      {
        website: "Mapcarta",
        url: "https://mapcarta.com/es/N1235400449",
      },
    ],
    en: {
      title: "Our Lady of the Mount Carmel Chapel",
      desc: (
        <p>
          The church of Our Lady of the Mount Carmel (cemetery) has a neoclassical belfry style, its old chapel was
          built between 1818 and 1846, the current one between 1980 and 1985. The cemetery underwent several
          renovations, currently it is very spacious and well maintained, it is located at the end of the town's main
          street.
        </p>
      ),
    },
    es: {
      title: "Capilla de Nuestra Señora del Carmen",
      desc: (
        <p>
          La iglesia de Nuestra Señora del Carmen (cementerio) tiene un estilo neoclásico espadaña, su antigua capilla
          fue construida entre 1818 y 1846, la actual entre 1980 y 1985. El cementerio sufrió varias remodelaciones, en
          la actualidad es muy amplio y bien cuidado, se encuentra finalizando la calle principal del municipio.
        </p>
      ),
    },
  },

  {
    id: "iglesiaSanMartinPorres",
    image: iglesiaSanMartinPorres,
    imageSource: "arquisantioquia.co",
    location:
      "https://www.google.com/maps/place/Parroquia+San+Martin+De+Porres/@6.5638532,-75.8270098,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b61381e993d3:0x395228ca48e9080e!8m2!3d6.5638532!4d-75.8244295!16s%2Fg%2F11bycmd970?entry=ttu",
    metersDistance: 850,
    price: "free",
    readMore: [
      {
        website: "Santa Fe de Antioquia",
        url: "https://www.santafedeantioquia.com.co/templos/",
      },
      {
        website: "Arquidiócesis Santa Fe de Antioquia",
        url: "https://www.arquisantioquia.co/parroquia-san-martin-de-porres-santa-fe-de-antioquia/",
      },
      {
        website: "Horarios Misa",
        url: "https://horariosmisa.co/antioquia/santa-fe-de-antioquia/parroquia-san-martin-de-porres/",
      },
    ],
    en: {
      title: "Saint Martin de Porres Parish",
      desc: (
        <p>
          Small Gothic style construction. Its founder was Archbishop Eladio Acosta Arteaga in 1973. Located in the
          neighborhood El Llano de Bolivar, it has currently improved its appearance, since it has been subjected to
          extensions to the sides, in addition to a small square adorned by the fronts of other parishes of the temple.
        </p>
      ),
    },
    es: {
      title: "Parroquia de San Martín de Porres",
      desc: (
        <p>
          Construcción pequeña de estilo gótico. Su fundador fue el arzobispo Eladio Acosta Arteaga en el año 1973.
          Ubicada en el barrio Llano de Bolívar, en la actualidad ha mejorado su aspecto, ya que ha sido sometida a
          ampliaciones hacia los costados, además con una pequeña plazoleta adornada por los frentes de otras parroquias
          del templo.
        </p>
      ),
    },
  },

  {
    id: "iglesiaSanPedroClaver",
    image: iglesiaSanPedroClaver,
    imageSource: "santafedigital.com.",
    location:
      "https://www.google.com/maps/place/Iglesia+San+Pedro+Claver/@6.5633941,-75.8324365,17z/data=!3m1!4b1!4m6!3m5!1s0x8e45b63e270cd291:0x541a9244c2617a38!8m2!3d6.5633941!4d-75.8298562!16s%2Fg%2F11bycjg08c?entry=ttu",
    metersDistance: 1040,
    price: "free",
    readMore: [
      {
        website: "Horarios Misa",
        url: "https://horariosmisa.co/antioquia/santa-fe-de-antioquia/iglesia-san-pedro-claver/",
      },
    ],
    en: {
      title: "Saint Peter Claver Church",
      desc: (
        <p>
          With a neoclassical style and baroque details, it was built in 1889. It is located in the elevated area of the
          municipality, it is a place of great architectural beauty, which has captured the admiration of visitors. Its
          design impresses with its majesty and charm. The building of this church is a true work of art, perfect for
          those who enjoy praying in the morning in a serene and beautiful environment. This church is not only a place
          of prayer, but also houses the Casa San Pedro Claver asylum, where the elder receive care and protection.
        </p>
      ),
    },
    es: {
      title: "Iglesia San Pedro Claver",
      desc: (
        <p>
          De estilo neoclásico con detalles barrocos, fue construida en 1889. Se encuentra situada en la zona elevada
          del municipio, es un lugar de gran belleza arquitectónica, que ha capturado la admiración de los visitantes.
          Su diseño impresiona por su majestuosidad y encanto. La edificación de esta iglesia es una verdadera obra de
          arte, perfecta para aquellos que disfrutan de rezar en las mañanas en un entorno sereno y hermoso. Esta
          iglesia no solo es un lugar de oración, sino también alberga el asilo Casa San Pedro Claver, donde los
          abuelitos reciben cuidado y protección.
        </p>
      ),
    },
  },
]
