import React from "react"
import Slider from "react-slick"

const DivSlider = ({ className = "", children, options }) => {
  const settings = {
    slidesToShow: Math.min(children.length, 2),
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: children.length > 1 && true,
    arrows: children.length > 1,
    adaptiveHeight: true,
    // vertical: true,
    // verticalSwiping: true,
    // swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(children.length, 1),
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
    ...options,
  }
  return (
    <Slider
      {...settings}
      className={`${className} ${
        !options?.hideGradientEdges &&
        children.length > 1 &&
        "relative before:content-[''] before:w-[5rem] lg:before:h-[100%] before:left-0 before:top-0 before:bg-gradient-to-r before:from-stone-300 dark:before:from-stone-900 before:to-transparent before:absolute before:z-10 after:content-[''] after:w-[5rem] lg:after:h-[100%] after:right-0 after:top-0 after:bg-gradient-to-l after:from-stone-300 dark:after:from-stone-900 after:to-transparent after:absolute after:z-10"
      }`}
    >
      {children}
    </Slider>
  )
}

export default DivSlider
