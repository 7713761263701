import React, { useState } from "react"
import { EditorState } from "draft-js"
import Form from "../components/Form"

const EditGallery = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const title = "Editar galería"

  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={title}>
        {title}
      </h1>
      <Form
        data={{
          title: "Agregar entrada a la Galería",
          inputs: [
            {
              type: "file",
              name: "images",
              label: "Imágenes",
              options: {
                maxSize: Math.pow(1024, 2) * 25,
                multiple: true,
                accept: {
                  "image/gif": [],
                  "image/jpeg": [],
                  "image/png": [],
                  "image/tiff": [],
                  "image/webp": [],
                },
              },
              extensions: [".gif", ".jpeg", ".jpg", ".png", ".tiff", ".webp"],
              required: true,
            },
            {
              type: "texteditor",
              name: "content",
              label: "Contenido",
              data: { editorState, setEditorState },
            },
            {
              type: "token",
              name: "token",
              label: "Clave dinámica",
              required: true,
              data: { label: "Solicitar clave" },
            },
          ],
          submit: {
            submitText: "Agregar entrada",
            submitEndpoint: "/api/gallery",
            precheck: { input: "token", endpoint: "/api/token" },
            resultOk: "Listo!",
            resultError: "Ocurrió un error",
          },
        }}
      />
    </div>
  )
}

export default EditGallery
