// https://midu.dev/como-crear-un-time-ago-sin-dependencias-intl-relativeformat/

/*
localeMatcher: 'best fit', 'lookup'
numeric: 'always', 'auto' para poner "ayer" o "anteayer"
style: 'long', 'short' o 'narrow'
*/
const RTF_OPTIONS = { localeMatcher: "best fit", numeric: "auto", style: "short" }

const DATE_UNITS = {
  second: 1,
  minute: 60,
  hour: 60 * 60,
  day: 60 * 60 * 24,
  week: 60 * 60 * 24 * 7,
  month: 60 * 60 * 24 * 7 * 4.345, // 4.345 is the average number of weeks in a month
  year: 60 * 60 * 24 * 7 * 4.345 * 12,
}

const funcs = {
  values: {},

  getDateFromTimestamp(timestamp_str) {
    this.values.timestamp = new Date(timestamp_str)
    return this
  },

  getSecondsDiff() {
    this.values.secondsElapsed = (Date.now() - this.values.timestamp) / 1000
    return this
  },

  getUnitAndValueDate() {
    // If > 1 day, round it up to whole days by adding remainder until midnight to seconds elapsed
    if (this.values.secondsElapsed >= DATE_UNITS.day) {
      const secsToMidnight = (new Date().setHours(24, 0, 0, 0) - Date.now()) / 1000
      this.values.secondsElapsed += secsToMidnight
    }
    // Override acc if greater than seconds per unit
    return Object.entries(DATE_UNITS).reduce(
      (acc, [unit, secondsPerUnit]) =>
        this.values.secondsElapsed >= secondsPerUnit
          ? { value: -Math.floor(this.values.secondsElapsed / secondsPerUnit), unit }
          : acc,
      // Default value
      { value: -1, unit: "second" }
    )
  },
}

export const getTimeAgo = (timestamp_str, language) => {
  const rtf = new Intl.RelativeTimeFormat(language, RTF_OPTIONS)
  const { value, unit } = funcs.getDateFromTimestamp(timestamp_str).getSecondsDiff().getUnitAndValueDate()
  return rtf.format(value, unit)
}
