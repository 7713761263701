import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import GalleryEntry from "../components/GalleryEntry"
import Loading from "../components/Loading"

const Gallery = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [lastEntries, setLastEntries] = useState(null)
  const [entriesError, setEntriesErrors] = useState(false)

  const updateEntries = useCallback(async () => {
    // Reset status
    setIsLoading(true)
    setLastEntries(null)
    // Get data from server
    try {
      const res = await fetch(`/api/gallery/${language}`, {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      })
      // Success, load data
      if (res.ok) setLastEntries(await res.json())
      else throw new Error(`${res.status} (${res.statusText})`)
    } catch (err) {
      // Show error
      console.error(err)
      setEntriesErrors(true)
    } finally {
      setIsLoading(false)
    }
  }, [language])

  useEffect(() => {
    updateEntries()
  }, [updateEntries, language])

  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={t("navbar.gallery")}>
        {t("navbar.gallery")}
      </h1>
      {isLoading ? (
        <Loading color="border-amber-700 dark:border-amber-400" size="6rem" margin="mx-auto my-20" />
      ) : entriesError ? (
        <p className="mx-auto my-5 w-1/2 text-center text-xl lg:text-3xl text-red-600 dark:text-red-400">
          {t("gallery.errorFetch")}
        </p>
      ) : lastEntries?.length > 0 ? (
        lastEntries?.map((data, i) => (
          <GalleryEntry
            key={data.gallery_id}
            {...data}
            last={i === lastEntries.length - 1}
            language={language}
            translated={language !== "es" ? `* ${t("gallery.translated")}` : ""}
          />
        ))
      ) : (
        <h2 className="h2 my-12">{t("gallery.nothing")}</h2>
      )}
    </div>
  )
}

export default Gallery
