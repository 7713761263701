import React from "react"
import { useTranslation } from "react-i18next"
import usePlacesAutoComplete, { getGeocode, getLatLng } from "use-places-autocomplete"
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react"
import { IoCloseCircleOutline } from "react-icons/io5"

/**
 * Based on the YouTube tutorial
 * https://www.youtube.com/watch?v=2po9_CIRW7I&t=2469s
 **/

const Places = ({ setIsSearching, setOrigin }) => {
  const { t } = useTranslation()
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutoComplete()

  const handleSelect = async (val = "") => {
    setValue(val, false)
    clearSuggestions()

    if (!val) {
      setOrigin("")
      return
    }

    setIsSearching(true)
    const results = await getGeocode({ address: val })
    setOrigin(await getLatLng(results[0]))
    setIsSearching(false)
  }

  return (
    <Combobox value={value} onChange={handleSelect}>
      <div className="relative w-11/12">
        <ComboboxInput
          displayValue={value}
          onChange={(e) => setValue(e.target.value || "")}
          disabled={!ready}
          className="w-full pl-4 pr-9 py-2.5 leading-8 rounded-lg z-10 bg-neutral-200 dark:bg-neutral-800 border-2 focus:border-amber-700 dark:focus:border-amber-500 focus:outline-none focus-ring-0 dark:active:border-amber-500"
          placeholder={t("location.origin")}
          autoComplete="off"
        />
        {value && (
          <IoCloseCircleOutline
            className="absolute w-6 h-6 right-2 top-1/2 -translate-y-1/2 hover:text-amber-700 dark:hover:text-amber-500 cursor-pointer"
            onClick={() => handleSelect()}
          />
        )}
      </div>
      <ComboboxOptions
        anchor="bottom"
        className="empty:hidden rounded-b-md w-[var(--input-width)] bg-neutral-200 dark:bg-neutral-800 border-2 border-t-0 border-amber-500 dark:border-amber-400"
      >
        {status === "OK" &&
          data.map(({ place_id, description }) => (
            <ComboboxOption
              key={place_id}
              value={description}
              className="data-[focus]:text-amber-700 dark:data-[focus]:text-amber-400 p-3 cursor-pointer"
            >
              {description}
            </ComboboxOption>
          ))}
      </ComboboxOptions>
    </Combobox>
  )
}

export default Places
