import React, { useState } from "react"
import Loading from "./Loading.jsx"

export const Token = ({ name, required, isSending, data }) => {
  const [isFetching, setIsFetching] = useState(false)

  const handleTokenQueryClick = async () => {
    setIsFetching(true)
    try {
      const res = await fetch("/api/token", {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: '{ "request": "token-gallery" }',
      })
      if (res.ok) alert("Se envió el token al correo electrónico del restaurante")
      else throw new Error(`${res.status} (${res.statusText})`)
    } catch (err) {
      alert(`No se pudo enviar el token\n${err}`)
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <div className="flex items-center">
      <input
        type="number"
        minLength="4"
        maxLength="4"
        name={name}
        id={name}
        autoComplete="false"
        required={required}
        disabled={isSending ?? "disabled"}
        className="h-10 p-4 rounded lg:rounded-xl border-b-2 border-stone-300 bg-white bg-opacity-20 focus:bg-opacity-40 focus:outline-none focus-ring-0 focus:border-yellow-800 dark:focus:border-yellow-800 shadow-sm shadow-neutral-500 text-center"
      />
      {isFetching ? (
        <div className="w-28 flex justify-center">
          <Loading size="1.5rem" />
        </div>
      ) : (
        <button
          type="button"
          className="text-amber-700 dark:text-amber-400 px-4 underline"
          onClick={handleTokenQueryClick}
        >
          {data.label}
        </button>
      )}
    </div>
  )
}
