import React from "react"
import { useTranslation } from "react-i18next"
import { FaRegFaceSadTear } from "react-icons/fa6"

const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <div className="mt-36 mx-auto w-fit max-w-[75%] md:max-w-[50%] lg:max-w-[40%] grid md:grid-cols-4 gap-8 lg:gap-y-3 text-center">
      <h1 className="h1 md:col-span-3 text-gradient-shadow md:text-start" content={t("notFound.title")}>
        {t("notFound.title")}
      </h1>
      <FaRegFaceSadTear className="md:row-span-2 text-7xl md:text-8xl md:self-end justify-self-center md:justify-self-end md:row-start-1" />
      <p className="md:col-span-3 text-2xl md:text-left">
        {t("notFound.text")
          .split(/\|/g)
          .map((text, i) => (
            <span key={i}>
              {text} <br />
            </span>
          ))}
      </p>
    </div>
  )
}

export default PageNotFound
