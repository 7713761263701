import React from "react"
import { useTranslation } from "react-i18next"
import Form from "../components/Form"
import dayjs from "dayjs"

const Reservations = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const shouldDisableTime = (val, view) => {
    const hour = val.get("hour")
    // Hours view
    if (view === "hours") return hour < 11 || hour > 22
    // Minutes view
    const minOfDay = hour * 60 + val.get("minute")
    return minOfDay < 11.5 * 60 || minOfDay > 22 * 60
  }

  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={t("navbar.reservations")}>
        {t("navbar.reservations")}
      </h1>

      <Form
        data={{
          title: t("reservations.title"),
          inputs: [
            { type: "text", name: "name", label: t("reservations.name"), required: true },
            { type: "email", name: "email", label: t("reservations.email"), required: true },
            { type: "tel", name: "phone", label: t("reservations.phone"), required: true },
            { type: "number", name: "people", label: t("reservations.people"), required: true, options: { min: 1 } },
            {
              type: "datetime",
              name: "datetime",
              label: t("reservations.datetime"),
              required: true,
              options: {
                disablePast: true,
                views: ["month", "day", "hours", "minutes"],
                ampm: false,
                timeSteps: { minutes: 15 },
                minDateTime: dayjs(),
                maxDateTime: dayjs().add(14, "day"),
                shouldDisableTime,
                slotProps: { textField: { required: true } },
                showDaysOutsideCurrentMonth: true,
                skipDisabled: true,
              },
            },
            { type: "hidden", name: "lang", data: language },
          ],
          submit: {
            submitText: t("send"),
            submitEndpoint: "/api/reservations",
            resultOk: "contact.result.ok",
            resultError: "contact.result.error",
          },
        }}
      />
    </div>
  )
}

export default Reservations
