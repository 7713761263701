import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const langs = [
  { code: "es", name: "Español", flag: "co" },
  { code: "en", name: "English", flag: "us" },
]

const findLangIndex = (searchCode) => langs.findIndex(({ code }) => code === searchCode)

const useLocale = () => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(localStorage.getItem("language") || i18n.language)

  const changeLanguage = () => {
    const newLangIndex = (findLangIndex(lang) + 1) % langs.length
    const newLang = langs[newLangIndex].code
    setLang(newLang)
  }

  useEffect(() => {
    i18n.changeLanguage(lang)

    // save theme to local storage
    localStorage.setItem("language", lang)
  }, [lang, i18n])

  return { language: langs[findLangIndex(lang)], changeLanguage }
}

export default useLocale
