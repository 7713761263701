import React, { useState } from "react"
import Stars from "./Star"
import { countries } from "./country-picker/lib/countries.ts"
import CountrySelector from "./country-picker/lib/selector.tsx"
import { useTranslation } from "react-i18next"
import { DateTimePicker } from "@mui/x-date-pickers"
import { FileInput } from "./FileInput.jsx"
import TextEditor from "./TextEditor.jsx"
import { convertToRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import Loading from "./Loading.jsx"
import { Token } from "./Token.jsx"

const Label = ({ name, label, required }) => {
  return (
    <label className="pt-2 md:py-2 md:text-end" htmlFor={name}>
      {label}: {required && <span className="text-red-600">*</span>}
    </label>
  )
}

const Form = ({
  data: {
    title,
    inputs,
    submit: { submitText, submitEndpoint, precheck, resultOk, resultError, callbackFunc = () => {} },
  },
}) => {
  const [rating, setRating] = useState(5)
  const [country, setCountry] = useState("CO")
  const [isOpen, setIsOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [showResult, setShowResult] = useState(null)
  const { t } = useTranslation()

  const selectedCountry = countries.find((option) => option.value === country)

  const doPrecheck = async (data) => {
    const { input, endpoint } = precheck
    const selectedToken = data[input]
    try {
      const data = await fetch(`${endpoint}/${selectedToken}`, { method: "GET" })
      const { message } = await data.json()
      if (message === "invalid token") throw new Error(message)
      return true
    } catch (err) {
      alert(err)
      console.error(err)
      return false
    } finally {
      setIsSending(false)
    }
  }

  const handleFormSubmit = async (e) => {
    // Avoid the page to reload
    e.preventDefault()
    // If it is already loading, do nothing else
    if (isSending) return
    // Button loading animation
    setIsSending(true)
    // Get the data and send to backend
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData.entries())
    Object.entries(data).forEach(([key, val]) => {
      if (val.startsWith("json-")) {
        // If val is a JSON stringified file, return it to an array
        data[key] = JSON.parse(val.replace("json-", ""))
      }
    })
    // Do precheck
    if (precheck && !(await doPrecheck(data))) {
      return
    }
    // Send the form
    try {
      const res = await fetch(submitEndpoint, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
      })
      if (res.ok) setShowResult("ok")
      else throw new Error(`${res.status} (${res.statusText})`)
    } catch (err) {
      setShowResult(err)
    } finally {
      callbackFunc()
    }
  }

  return (
    <div className="w-screen md:w-8/12 md:max-w-[52rem] mt-6 -mx-8 -mb-12 md:mb-0 md:mx-auto text-lg lg:text-xl md:rounded-2xl md:shadow-lg shadow-neutral-700 md:bg-yellow-700 md:dark:bg-ambar-950 md:dark:bg-opacity-60">
      <h2 className="h2 mt-4 mb-2 md:text-white dark:md:text-amber-50">{title}</h2>
      {showResult ? (
        <div className="bg-yellow-100 bg-opacity-50 dark:bg-yellow-100 dark:bg-opacity-40 text-yellow-950 h-96 flex flex-col items-center justify-center px-10 text-center">
          {showResult === "ok" ? (
            t(resultOk)
              .split(/\|/g)
              .map((line, i) => (
                <p className="text-green-800 font-semibold" key={i}>
                  {line}
                </p>
              ))
          ) : (
            <>
              <p className="text-red-700 font-semibold">{t(resultError)}</p>
              <p>{showResult}</p>
            </>
          )}
        </div>
      ) : (
        <form
          className="p-4 px-8 md:p-10 flex flex-col md:grid md:grid-cols-[auto,_1fr] gap-2 md:gap-4 bg-yellow-700 dark:bg-ambar-950 bg-opacity-80 dark:bg-opacity-30 md:bg-gray-200 dark:md:bg-gray-200 md:bg-opacity-40 dark:md:bg-opacity-40 text-yellow-950 dark:text-yellow-50 dark:md:text-yellow-950"
          onSubmit={handleFormSubmit}
        >
          {inputs.map(({ type, name, label, info, required, options, extensions, data }) => {
            if (["email", "number", "tel", "text", "url"].includes(type))
              return (
                <React.Fragment key={name}>
                  <Label name={name} label={label} required={required} />
                  <input
                    type={type}
                    name={name}
                    id={name}
                    autoComplete="false"
                    required={required}
                    disabled={isSending ?? "disabled"}
                    {...options}
                    className="w-full h-10 p-4 rounded lg:rounded-xl border-b-2 border-stone-300 bg-white bg-opacity-20 focus:bg-opacity-40 focus:outline-none focus-ring-0 focus:border-yellow-800 dark:focus:border-yellow-800 shadow-sm shadow-neutral-500"
                  />
                </React.Fragment>
              )
            if (["textarea"].includes(type))
              return (
                <React.Fragment key={name}>
                  <div className="flex md:flex-col">
                    <Label name={name} label={label} required={required} />
                    {info && (
                      <p className="text-sm lg:text-lg italic font-extralight md:text-right text-neutral-300 md:text-neutral-700 !leading-none max-w-[17ch] self-end m-1.5 md:-mt-2">
                        {info}
                      </p>
                    )}
                  </div>
                  <textarea
                    name={name}
                    id={name}
                    required={required}
                    disabled={isSending ?? "disabled"}
                    {...options}
                    className="w-full h-32 px-4 py-2 rounded lg:rounded-xl border-b-2 border-stone-300 bg-white bg-opacity-20 focus:bg-opacity-40 focus:outline-none focus-ring-0 focus:border-yellow-800 dark:focus:border-yellow-800 shadow-sm shadow-neutral-500"
                  />
                </React.Fragment>
              )
            if (["rating"].includes(type))
              return (
                <React.Fragment key={name}>
                  <Label name={name} label={label} required={required} />
                  <Stars rating={rating} setRating={!isSending && setRating} />
                  <input type="hidden" name={name} id={name} value={rating} />
                </React.Fragment>
              )
            if (["country"].includes(type))
              return (
                <React.Fragment key={name}>
                  <Label name={name} label={label} required={required} />
                  <CountrySelector
                    open={isOpen}
                    onToggle={() => setIsOpen((prev) => !prev)}
                    onChange={setCountry}
                    onLoad={(val) => setCountry(val)}
                    selectedValue={selectedCountry}
                    disabled={isSending ?? "disabled"}
                    {...options}
                  />
                  <input
                    type="hidden"
                    name="country"
                    value={data === "value" ? selectedCountry.value.toLowerCase() : selectedCountry.title}
                  />
                </React.Fragment>
              )
            if (["datetime"].includes(type))
              return (
                <React.Fragment key={name}>
                  <Label name={name} label={label} required={required} />
                  <DateTimePicker
                    required={required}
                    name={name}
                    id={name}
                    disabled={isSending ?? "disabled"}
                    {...options}
                    className="[&>div]:w-full [&>div]:h-10 [&_input]:p-4 [&>div]:rounded lg:[&>div]:rounded-xl [&>div]:border-b-2 [&>div]:border-stone-300 [&>div]:bg-white [&>div]:bg-opacity-20 [&>div]:focus-within:bg-opacity-40 [&_input]:focus:outline-none [&_input]:focus-ring-0 [&>div]:focus-within:border-yellow-800 dark:[&>div]:focus-within:border-yellow-800 [&>div]:shadow-sm [&>div]:shadow-neutral-500 [&_input]:text-yellow-950 [&_input]:font-theanoDidot [&_input]:text-lg lg:[&_input]:text-xl [&_fieldset]:border-none"
                  />
                </React.Fragment>
              )
            if (["file"].includes(type))
              return (
                <React.Fragment key={name}>
                  <Label name={name} label={label} required={required} />
                  <FileInput {...{ name, t, required, options, extensions, disabled: isSending }} />
                </React.Fragment>
              )
            if (["texteditor"].includes(type)) {
              const content = data.editorState.getCurrentContent()
              return (
                <React.Fragment key={name}>
                  <Label name={name} label={label} required={required} />
                  <TextEditor name={name} required={required} {...data} disabled={isSending} />
                  <input name={name} type="hidden" value={JSON.stringify(convertToRaw(content))} />
                  <input name="html" type="hidden" value={stateToHTML(content)} />
                </React.Fragment>
              )
            }
            if (["hidden"].includes(type))
              return (
                <React.Fragment key={name}>
                  <input name={name} type="hidden" value={data} />
                </React.Fragment>
              )
            if (["token"].includes(type))
              return (
                <React.Fragment key={name}>
                  <Label name={name} label={label} required={required} />
                  <Token {...{ name, required, isSending, data }} />
                </React.Fragment>
              )
            return <></>
          })}
          <button type="submit" disabled={isSending} className="mx-auto my-6 md:my-0 col-span-2">
            {isSending ? (
              <Loading />
            ) : (
              <div className="px-6 py-3 rounded-lg bg-neutral-300 hover:bg-amber-300 active:bg-amber-200 border-2 border-neutral-500 text-yellow-950">
                {submitText}
              </div>
            )}
          </button>
        </form>
      )}
    </div>
  )
}

export default Form
