import translationEn from "../locale/en/translation.json"
import translationEs from "../locale/es/translation.json"
import faqEn from "../locale/en/faq.json"
import faqEs from "../locale/es/faq.json"

export const resources = {
  en: {
    translation: translationEn,
    faq: faqEn,
  },
  es: {
    translation: translationEs,
    faq: faqEs,
  },
}
