import { getExchangeRate } from "./exchangeRate"

export const fromBytes = (b) => {
  if (b < 1024) return `${+b.toFixed(0)} bytes`
  b /= 1024
  if (b < 1024) return `${+b.toFixed(0)} KB`
  b /= 1024
  if (b < 1024) return `${+b.toFixed(1)} MB`
  b /= 1024
  if (b < 1024) return `${+b.toFixed(2)} GiB`
  b /= 1024
  return `${+b.toFixed(2)} TB`
}

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve({ filename: file.name, path: fileReader.result })
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const distance = (meters, system) => {
  const equiv = {
    ft: 0.3084, // 1 ft = 0.3084 m
    km: 1000, // 1 km = 1000 m
    mi: 5280, // 1 mi = 5280 ft
  }
  if (system === "metric") {
    // If less than 750 m, show meters
    if (meters < 750) return `${+meters.toFixed(0)} m`
    // If less than 10 km show 1 decimal place
    const km = meters / equiv.km
    return `${+km.toFixed(km > 10 ? 0 : 1)} km`
  } else {
    // If less than 1000 ft, show feet
    const ft = meters / equiv.ft
    if (ft < 1000) return `${+ft.toFixed(0)} ft`
    // If less than 5 mi show 1 decimal place
    const mi = ft / equiv.mi
    return `${+mi.toFixed(mi > 10 ? 0 : 1)} mi`
  }
}

export const currencyConverter = async (copValue, currencies) => {
  const exchangeRates = await getExchangeRate("COP", currencies)
  const res = currencies.map(async (curr) => {
    let val = copValue * exchangeRates[curr]
    const roundFactor = Math.pow(10, val > 100 ? 0 : val > 10 ? 1 : 2)
    val = (Math.round(val * roundFactor) / roundFactor).toLocaleString("en-US")
    return `${val} ${curr}`
  })
  return Promise.all(res)
}
