import React, { useCallback, useEffect, useState } from "react"
import { CircleFlag } from "react-circle-flags"
import { useTranslation } from "react-i18next"
import Form from "../components/Form"
import DivSlider from "../components/DivSlider"
import Stars from "../components/Star"
import { countries } from "../components/country-picker/lib/countries.ts"
import Loading from "../components/Loading.jsx"

const Reviews = () => {
  const maxReviews = 20
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [isLoading, setIsLoading] = useState(null)
  const [lastReviews, setLastReviews] = useState(null)
  const [reviewsError, setReviewErrors] = useState(false)

  const updateReviews = useCallback(async () => {
    // Reset status
    setIsLoading(true)
    setLastReviews(null)
    // Get data from server
    try {
      const res = await fetch(`/api/reviews/${language}/${maxReviews}`, {
        headers: { "Content-Type": "application/json" },
        method: "GET",
      })
      if (res.ok) {
        // Success, load data
        setLastReviews(await res.json())
      } else throw new Error(`${res.status} (${res.statusText})`)
    } catch (err) {
      // Show error
      console.error(err)
      setReviewErrors(true)
    } finally {
      setIsLoading(false)
    }
  }, [language])

  useEffect(() => {
    updateReviews()
  }, [updateReviews, language])

  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={t("navbar.reviews")}>
        {t("navbar.reviews")}
      </h1>
      {/* Show Reviews */}
      {isLoading ? (
        <Loading color="border-amber-700 dark:border-amber-400" size="6rem" margin="mx-auto my-20" />
      ) : (
        lastReviews?.length > 0 && (
          <>
            <DivSlider className="mx-auto mt-12 mb-4 md:max-w-[60%] lg:max-w-[90%] xl:max-w-[75%] w-full">
              {lastReviews.map((rev) => {
                const { reviews_id, name, text, rating, country, createdAt } = rev
                const date = new Date(createdAt).toLocaleDateString(language, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
                return (
                  <div key={reviews_id} className="p-2 font-garamond">
                    <div className="rounded-xl shadow shadow-neutral-500 dark:shadow-neutral-600 bg-neutral-200 dark:bg-neutral-800 p-3 grid grid-cols-12 grid-rows-[repeat(4,_min-content)] min-h-52">
                      <CircleFlag
                        countryCode={country}
                        title={countries.find((option) => option.value.toLowerCase() === country).title}
                        className="w-9/12 col-span-2 row-span-3 justify-self-center self-center"
                      />
                      <p className="font-semibold text-xl md:text-2xl col-span-10 self-center">{name}</p>
                      <Stars
                        rating={rating}
                        className="col-span-5 self-center text-yellow-600 dark:text-yellow-400 text-xl"
                      />
                      <p className="col-span-5 font-light text-neutral-600 dark:text-neutral-400 text-right self-center">
                        {date}
                      </p>
                      <div className="w-full col-span-10 mt-1 border-b-2 border-neutral-400 dark:border-neutral-600" />
                      <p className="col-span-12 text-lg leading-6 first-letter:capitalize first-letter:font-semibold first-letter:text-2xl first-letter:mr-0.5">
                        {text}
                      </p>
                    </div>
                  </div>
                )
              })}
            </DivSlider>
            <p className="mx-auto mb-5 w-5/6 md:w-1/3 text-center text-md lg:text-xl font-light tracking-tighter leading-tight text-neutral-600 dark:text-neutral-400 italic">
              * {t("reviews.translated")}
            </p>
          </>
        )
      )}
      {reviewsError && (
        <p className="mx-auto my-5 w-1/2 text-center text-xl lg:text-3xl text-red-600 dark:text-red-400">
          {t("reviews.errorFetch")}
        </p>
      )}
      {/* Write a Review Form */}
      <Form
        data={{
          title: t("reviews.writeReview"),
          inputs: [
            { type: "text", name: "name", label: t("reviews.name"), required: true },
            { type: "country", name: "country", label: t("reviews.country"), data: "value" },
            { type: "rating", name: "rating", label: t("reviews.rating") },
            {
              type: "textarea",
              name: "text",
              label: t("reviews.text"),
              info: t("reviews.textInfo"),
              required: true,
              options: { minLength: 30 },
            },
          ],
          submit: {
            submitText: t("send"),
            submitEndpoint: "/api/reviews",
            resultOk: "reviews.result.ok",
            resultError: "reviews.result.error",
            callbackFunc: updateReviews,
          },
        }}
      />
    </div>
  )
}

export default Reviews
