export const getExchangeRate = async (base, quotes) => {
  try {
    const res = await fetch(`https://open.er-api.com/v6/latest/${base}`)
    if (res.ok) {
      const { rates } = await res.json()
      return Object.keys(rates)
        .filter((key) => quotes.includes(key))
        .reduce((obj, key) => ({ ...obj, [key]: +rates[key] }), {})
    } else throw new Error(`${res.status} (${res.statusText})`)
  } catch (err) {
    console.error(err)
  }
}
