import React from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { toolbarOptions } from "../utils/toolbarOptions"

const TextEditor = ({ editorState, setEditorState }) => {
  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      wrapperClassName="demo-wrapper w-full mx-auto rounded lg:rounded-xl bg-white bg-opacity-20 focus:bg-opacity-40 focus:outline-none focus-ring-0 focus:border-yellow-800 dark:focus:border-yellow-800 shadow-sm shadow-neutral-500"
      editorClassName="editer-content !h-80 overflow-auto mx-2 [&_.public-DraftStyleDefault-block]:!my-2 md:text-xl"
      toolbarClassName="toolbar-class"
      toolbar={toolbarOptions}
    />
  )
}

export default TextEditor
