import useLocale from "../hooks/useLocale"
import { CircleFlag } from "react-circle-flags"
import { useTranslation } from "react-i18next"

const LangSwitcher = () => {
  const { language, changeLanguage } = useLocale()
  const { t } = useTranslation()

  return (
    <CircleFlag
      countryCode={language.flag}
      title={t("changeLanguage")}
      className="w-7 h-7 p-1 md:w-8 md:h-8 md:p-1 group-hover:md:p-0.5 rounded-full object-cover cursor-pointer select-none hover:saturate-150"
      onClick={changeLanguage}
    />
  )
}

export default LangSwitcher
