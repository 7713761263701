import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { BsMoonStarsFill } from "react-icons/bs"
import { BsFillSunFill } from "react-icons/bs"
import { ThemeContext } from "../hooks/useTheme"

const ThemeSwitcher = () => {
  const { theme, setTheme } = useContext(ThemeContext)
  const toggleDarkMode = () => {
    setTheme((prev) => (prev === "dark" ? "light" : "dark"))
  }

  const { t } = useTranslation()

  return (
    <div className="h-7 flex items-center hover:text-amber-700 dark:hover:text-amber-400">
      {theme === "dark" ? (
        <BsMoonStarsFill
          className="w-6 h-6 p-1 md:w-8 md:h-8 md:p-1.5 group-hover:md:p-0.5 group-hover:md:pt-1 cursor-pointer drop-shadow"
          onClick={toggleDarkMode}
          title={t("lightMode")}
        />
      ) : (
        <BsFillSunFill
          className="w-6 h-6 p-0.5 md:w-8 md:h-8 md:p-1 group-hover:md:p-0 group-hover:md:pt-0.5 cursor-pointer drop-shadow"
          onClick={toggleDarkMode}
          title={t("darkMode")}
        />
      )}
    </div>
  )
}

export default ThemeSwitcher
