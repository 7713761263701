import i18n from "i18next"
import { initReactI18next, useTranslation } from "react-i18next"
import { Route, Routes } from "react-router-dom"
import { FloatingWhatsApp } from "react-floating-whatsapp"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en"
import "dayjs/locale/es"

import { resources } from "../utils/translationResources"
import logo512 from "../assets/img/logo512.png"
import Home from "./Home"
import Location from "./Location"
import Navbar from "../components/Navbar"
import useTheme, { ThemeContext } from "../hooks/useTheme"
import PageNotFound from "./PageNotFound"
import Reviews from "./Reviews"
import Contact from "./Contact"
import Faq from "./Faq"
import Reservations from "./Reservations"
import WorkWithUs from "./WorkWithUs"
import ToDo from "./ToDo"
import Gallery from "./Gallery"
import EditGallery from "./EditGallery"

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language"),
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
})

function App() {
  const [theme, setTheme] = useTheme()
  const { t, i18n } = useTranslation()

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
        <div className="App font-theanoDidot">
          <header>
            <Navbar />
            <FloatingWhatsApp
              phoneNumber="573113066308"
              accountName={"Barroco " + t("rest")}
              avatar={logo512}
              chatMessage={t("whatsapp.chatMessage")}
              placeholder={t("whatsapp.placeholder")}
              statusMessage={t("whatsapp.statusMessage")}
              darkMode={theme === "dark"}
            />
          </header>
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/comentarios" element={<Reviews />} />
              <Route path="/contacto" element={<Contact />} />
              <Route path="/editar-galeria" element={<EditGallery />} />
              <Route path="/galeria" element={<Gallery />} />
              <Route path="/preguntas-frecuentes" element={<Faq />} />
              <Route path="/que-hacer-en-santa-fe" element={<ToDo />} />
              <Route path="/reservas" element={<Reservations />} />
              <Route path="/trabaja-con-nosotros" element={<WorkWithUs />} />
              <Route path="/ubicacion" element={<Location />} />
              <Route path="*" exact={true} element={<PageNotFound />} />
            </Routes>
          </main>
        </div>
      </LocalizationProvider>
    </ThemeContext.Provider>
  )
}

export default App
