import React from "react"

const Loading = ({ size = "3.5rem", color = "border-current", margin = "mx-3" }) => {
  return (
    <div
      className={`animate-spin rounded-full border-4 border-solid !border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite] ${color} ${margin}`}
      style={{ width: size, height: size }}
    />
  )
}

export default Loading
