import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toDoPlaces } from "../utils/toDoPlaces"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaClock, FaPersonWalking } from "react-icons/fa6"
import { IoTicket } from "react-icons/io5"
import { currencyConverter, distance } from "../utils/utils"

const Card = ({
  language,
  t,
  data: {
    id,
    image,
    imageSource,
    location,
    metersDistance,
    price,
    readMore,
    [language]: { title, desc, schedule },
  },
}) => {
  const [priceArr, setPriceArr] = useState(null)

  useEffect(() => {
    if (typeof price !== "string") {
      const getPrices = async () => {
        const prices = await currencyConverter(price, ["COP", "USD", "EUR"])
        setPriceArr(prices)
      }
      getPrices()
    }
  }, [price])

  return (
    <div
      key={id}
      className="w-full md:w-[70%] lg:w-[40%] 2xl:w-[30%] h-fit shadow-md rounded-lg bg-stone-200 dark:bg-stone-700 overflow-hidden"
    >
      <div className="relative shadow shadow-neutral-600 dark:shadow-neutral-800 bg-neutral-300 dark:bg-neutral-700 w-full">
        <img
          src={image}
          alt={title}
          className="w-full aspect-[16_/_10] object-fill dark:grayscale-[30%] dark:opacity-70"
        />
        {imageSource && (
          <p className="absolute rounded-tl-md bottom-0 right-0 px-1.5 py-0.5 text-white text-xs italic font-thin bg-neutral-500 dark:bg-neutral-700">
            {t("todo.source")}: {imageSource}
          </p>
        )}
      </div>
      <h3 className="h3 mx-8 mt-3 mb-1.5">{title}</h3>
      <div className="px-6 pb-4 flex flex-col gap-3">
        {desc}
        <p className="-mt-1.5">
          {t("todo.readMore") + " "}
          {readMore.map(({ website, url }, i) => (
            <React.Fragment key={website}>
              <a href={url} target="_blank" rel="noreferrer" className="text-amber-700 dark:text-amber-400">
                {website}
              </a>
              {i < readMore.length - 2 ? ", " : i === readMore.length - 2 ? ` ${t("and")} ` : "."}
            </React.Fragment>
          ))}
        </p>
        <hr className="border-t-2 -my-1 mx-2 border-neutral-300 dark:border-neutral-600" />
        <div className="grid grid-cols-[1rem,_1fr] items-start gap-x-1.5 md:gap-x-2 gap-y-1">
          {
            /* Open hours */
            schedule && (
              <>
                <FaClock className="w-3 h-3 md:w-3.5 md:h-3.5 text-neutral-600 dark:text-neutral-400 mt-[0.2rem] md:mt-1 mx-auto" />
                {schedule}
              </>
            )
          }
          {/* Prices in COP / USD / EUR or Free */}
          <IoTicket className="text-neutral-600 dark:text-neutral-400 mt-0.5 mx-auto" />
          {priceArr ? priceArr.join(" / ") + " *" : t(`todo.${price}`)}
          {/* Link to Google Maps */}
          <FaMapMarkerAlt className="text-neutral-600 dark:text-neutral-400 mt-0.5 mx-auto" />
          <a href={location} rel="noreferrer" target="_blank" className="text-amber-700 dark:text-amber-400">
            Google Maps
          </a>
          {/* Distance in meters/km or feet/miles */}
          <FaPersonWalking className="text-neutral-600 dark:text-neutral-400 mt-0.5 mx-auto" />
          {distance(metersDistance, language === "en" ? "imperial" : "metric")} {t("todo.fromRest")}
          {
            /* Currency Exchange Rates Atrribution */
            priceArr && (
              <p className="text-neutral-500 dark:text-neutral-400 text-right text-xs md:text-sm italic col-span-2">
                <a href="https://www.exchangerate-api.com" target="_blank" rel="noreferrer">
                  * {t("exchangeRates")}
                </a>
              </p>
            )
          }
        </div>
      </div>
    </div>
  )
}

const ToDo = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={t("navbar.toDo")}>
        {t("navbar.toDo")}
      </h1>
      <div className="flex flex-wrap justify-center w-full md:10/12 gap-10 mx-auto my-8 text-sm md:text-lg leading-5 md:leading-6">
        {toDoPlaces
          .sort((a, b) => a.metersDistance - b.metersDistance)
          .map((data) => (
            <Card data={data} language={language} t={t} key={data.id} />
          ))}
      </div>
    </div>
  )
}

export default ToDo
