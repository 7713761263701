import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { TbCircleCaretDown } from "react-icons/tb"

const Faq = () => {
  const [activeQuestion, setActiveQuestion] = useState(-1)
  const { t } = useTranslation()
  const faqs = t("faq:data", { returnObjects: true })

  const handleShowQuestion = (index) => {
    setActiveQuestion((prev) => (prev === index ? -1 : index))
  }

  return (
    <div className="base">
      <h1 className="h1 text-gradient-shadow" content={t("faq:title")}>
        {t("faq:title")}
      </h1>
      {faqs.map(({ question, answer }, i) => (
        <div key={question} className="mt-6 -mx-8 md:mx-auto w-screen md:w-full lg:w-10/12 xl:w-8/12">
          <div
            className={
              "flex justify-between bg-neutral-200 dark:bg-neutral-700 bg-opacity-50 dark:bg-opacity-50 p-4 cursor-pointer " +
              (activeQuestion === i ? "md:rounded-t-xl text-amber-700 dark:text-amber-400" : "md:rounded-xl")
            }
            onClick={() => handleShowQuestion(i)}
          >
            <h2 className="h2 lg:text-2xl duration-300 text-left">{question}</h2>
            <TbCircleCaretDown
              className={
                "flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8 duration-700 " + (activeQuestion === i ? "rotate-180" : "")
              }
            />
          </div>
          <p
            className={
              "lg:text-xl md:rounded-b-lg bg-neutral-200 dark:bg-neutral-700 bg-opacity-90 dark:bg-opacity-90 p-4 " +
              (activeQuestion === i ? "" : "hidden")
            }
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </div>
      ))}
    </div>
  )
}

export default Faq
