import React from "react"
import { useTranslation } from "react-i18next"

const NavBarLink = ({ Icon, text, link, newTab, isSelected }) => {
  const { t } = useTranslation()

  return (
    <a
      href={link}
      target={newTab ? "_blank" : ""}
      rel="noreferrer"
      className={[
        "flex justify-start items-end gap-2 h-8 group-hover:pr-3 font-semibold text-lg md:text-xl hover:text-amber-700 dark:hover:text-amber-400",
        isSelected && "text-amber-700 dark:text-amber-400",
      ].join(" ")}
    >
      <Icon className="w-7 h-7 p-1.5 md:p-0.5 m-0.5 drop-shadow flex-shrink-0" />
      <p className="md:hidden md:group-hover:inline-flex pb-px first-letter:capitalize">{t(text)}</p>
    </a>
  )
}

export default NavBarLink
