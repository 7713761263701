import { BsFillQuestionCircleFill } from "react-icons/bs"
import { FaCameraRetro, FaTripadvisor } from "react-icons/fa"
import { FaCalendarCheck, FaCommentDots, FaPhoneVolume, FaMapLocationDot } from "react-icons/fa6"
import { IoRestaurant } from "react-icons/io5"
import { LuListTodo } from "react-icons/lu"
import { MdWork } from "react-icons/md"

const newTab = true

export const navLinks = [
  { text: "navbar.location", link: "/ubicacion", Icon: FaMapLocationDot },
  { text: "navbar.menu", link: "/menu", Icon: IoRestaurant },
  { text: "navbar.gallery", link: "/galeria", Icon: FaCameraRetro },
  { text: "navbar.reviews", link: "/comentarios", Icon: FaCommentDots },
  {
    text: "Tripadvisor",
    link: "https://www.tripadvisor.co/Restaurant_Review-g672067-d26545598-Reviews-Barroco_Restaurante_Santa_Fe_de_Antioquia-Santa_Fe_de_Antioquia_Antioquia_Depart.html?m=19905",
    Icon: FaTripadvisor,
    newTab,
  },
  { text: "navbar.toDo", link: "/que-hacer-en-santa-fe", Icon: LuListTodo },
  { text: "navbar.faq", link: "/preguntas-frecuentes", Icon: BsFillQuestionCircleFill },
  { text: "navbar.reservations", link: "/reservas", Icon: FaCalendarCheck },
  { text: "navbar.contact", link: "/contacto", Icon: FaPhoneVolume },
  { text: "navbar.workWithUs", link: "/trabaja-con-nosotros", Icon: MdWork },
]
