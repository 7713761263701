import React, { useState } from "react"
import { HiMenu } from "react-icons/hi"
import LangSwitcher from "./LangSwitcher"
import ThemeSwitcher from "./ThemeSwitcher"
import NavBarLink from "./NavBarLink"
import { navLinks } from "../utils/navLinks"
import { useLocation } from "react-router-dom"
import { FaLaptopCode } from "react-icons/fa6"
import { useTranslation } from "react-i18next"

const Navbar = () => {
  const [mobileMenuShown, setMobileMenuShown] = useState(false)
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const handleMobileMenuClick = () => {
    setMobileMenuShown((prev) => !prev)
  }

  return (
    <>
      {/* Space outside Mobile Menu */}
      <div
        className={["absolute w-full h-full z-40", !mobileMenuShown && "hidden"].join(" ")}
        onClick={handleMobileMenuClick}
      />
      <div
        className={[
          "fixed top-0 left-0 h-14 md:h-full w-full md:w-14 md:hover:w-fit bg-neutral-200 dark:bg-neutral-700 bg-opacity-25 dark:bg-opacity-25 md:hover:bg-opacity-40 md:hover:dark:bg-opacity-70 md:hover:backdrop-blur-lg backdrop-blur-sm z-50 flex md:flex-col justify-start max-w-screen p-3 overflow-hidden group",
          mobileMenuShown && "h-auto bg-opacity-40 dark:bg-opacity-40 !backdrop-blur-lg",
        ].join(" ")}
      >
        <div className="w-full flex flex-col gap-2.5 md:gap-7 text-nowrap">
          <a href="/">
            <h1 className="h1 text-xl md:text-2xl lg:text-3xl xl:text-4xl font-trajanPro font-black text-start md:!text-center text-yellow-200 text-shadow-sm shadow-black after:content-['arroco'] md:after:hidden md:group-hover:after:inline-block md:group-hover:text-start md:group-hover:pl-[0.2rem]">
              B
            </h1>
          </a>
          {/* Links */}
          {navLinks.map((link, i) => (
            <NavBarLink {...link} isSelected={link.link === pathname} key={i} />
          ))}
        </div>
        <div className="absolute right-2 md:right-auto md:left-2 md:bottom-2 flex md:flex-col md:group-hover:flex-row gap-2.5 md:gap-1.5 md:group-hover:md:gap-5 md:group-hover:mx-2">
          <LangSwitcher />
          <ThemeSwitcher />
          <a
            href="https://albotero.com"
            target="_blank"
            rel="noreferrer"
            title={t("navbar.developer")}
            className="hover:text-amber-700 dark:hover:text-amber-400"
          >
            <FaLaptopCode className="w-7 h-7 p-1 md:w-8 md:h-8 md:p-1 group-hover:md:p-0 drop-shadow" />
          </a>
          {/* Mobile Menu */}
          <HiMenu
            className={[
              "w-10 h-8 px-1 py-0.5 -mt-0.5 ml-0.5 md:hidden rounded",
              mobileMenuShown && "bg-neutral-300 dark:bg-neutral-600",
            ].join(" ")}
            onClick={handleMobileMenuClick}
          />
        </div>
      </div>
    </>
  )
}

export default Navbar
