/* 
https://jpuri.github.io/react-draft-wysiwyg/#/docs
*/

export const toolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    // "list",
    "textAlign",
    "colorPicker",
    "link",
    "emoji",
    // "remove",
    "history",
  ],
  inline: {
    options: ["bold", "italic", "underline", "strikethrough"],
  },
  blockType: {
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", "Code"],
  },
  fontSize: {
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
  },
  fontFamily: {
    options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
  },
  list: {
    options: ["unordered", "ordered", "indent", "outdent"],
  },
  textAlign: {
    options: ["left", "center", "right", "justify"],
  },
  link: {
    options: ["link", "unlink"],
  },
  history: {
    options: ["undo", "redo"],
  },
}
